<!-- src/components/SwaggerUI.vue -->
<template>
  <div id="swagger-ui"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from 'vue';
import SwaggerUI from 'swagger-ui-dist/swagger-ui-es-bundle';
import { getOpenApiJson } from '../utils/s3Utils'; // Adjust the import path as needed

export default defineComponent({
  name: 'SwaggerUIComponent',
  props: {
    fileKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const renderSwaggerUI = async () => {
      const openApiJson = await getOpenApiJson(props.fileKey);
      if (openApiJson) {
        SwaggerUI({
          spec: openApiJson,
          dom_id: '#swagger-ui'
        });
      }
    };

    onMounted(() => {
      renderSwaggerUI();
    });

    watch(() => props.fileKey, () => {
      renderSwaggerUI();
    });

    return {};
  },
});
</script>

<style scoped>
@import "~swagger-ui-dist/swagger-ui.css";
</style>
