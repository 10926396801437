// src/utils/s3Client.ts
import { BucketCannedACL, PutBucketAclCommand, PutBucketCorsCommand, PutBucketPolicyCommand, PutBucketWebsiteCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import awsConfig from '@/aws-export';

const cognitoIdentityClient = new CognitoIdentityClient({ region: 'eu-central-1' })

const cognitoIdentityPoolConfig = fromCognitoIdentityPool({
    clientConfig: { ...cognitoIdentityClient, region: 'eu-central-1' },
    identityPoolId: awsConfig.Auth.Cognito.identityPoolId})

const s3Client = new S3Client({
  region: awsConfig.Storage.S3.region,
  credentials: await cognitoIdentityPoolConfig()
});

export { s3Client };