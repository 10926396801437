// src/main.ts
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {Amplify} from 'aws-amplify';
import awsConfig from './aws-export';

Amplify.configure(awsConfig);

const app = createApp(App);
app.use(router);
app.mount('#app');