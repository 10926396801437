export const stage = process.env.VUE_APP_STAGE

if(!stage){
  throw new Error('stage is undefined')
}

export const identityPoolId = {
  ekadar: 'eu-central-1:75b546a5-696f-44e9-a2fb-d306da97b5ee',
  dev: '',
  prod: ''
}[stage] || 'identitypool id is undefined'

