// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from './components/Home.vue';
import OpenApiList from './components/OpenApiList-Item.vue';
import SwaggerUIComponent from './components/Swagger-UI.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/openapi-list',
    name: 'OpenApiList',
    component: OpenApiList
  },
  {
    path: '/:fileKey',
    name: 'SwaggerUI',
    component: SwaggerUIComponent,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
