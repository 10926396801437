<!-- src/components/Home.vue -->
<template>
  <div>
    <h1>Home</h1>
    <router-link to="/openapi-list">Browse OpenAPI Documentation</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home-Item',
});
</script>

<style scoped>
/* Your styles here */
</style>
