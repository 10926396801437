<template>
  <div>
    <h1>Browse OpenAPI Documentation</h1>
    <ul>
      <li v-for="file in files" :key="file.Key">
        <router-link :to="{ name: 'SwaggerUI', params: { fileKey: file.Key } }">
          {{ stripExtension(file.Key) }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { listOpenApiFiles } from '../utils/s3Utils';

export default defineComponent({
  name: 'OpenApiList',
  setup() {
    const files = ref<any[]>([]);

    onMounted(async () => {
      files.value = await listOpenApiFiles();
    });

    const stripExtension = (fileName: string) => {
      return fileName.replace(/\.json$/, '');
    };

    return {
      files,
      stripExtension,
    };
  },
});
</script>

<style scoped>
/* Your styles here */
</style>
